import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import styles from './ProductList.module.css';

function ProductList({ category, selectProduct, goBack }) {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            const productCollection = collection(db, "products");
            const productQuery = query(productCollection, where("category_name", "==", category.name));
            const productSnapshot = await getDocs(productQuery);
            const productList = productSnapshot.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .filter(product => product.inStock);
            setProducts(productList);
        };

        fetchProducts();
    }, [category]);

    return (
        <div>
            <h2 className={styles.productTitle}>{category.name.toUpperCase()}</h2>
            <ul className={styles.productList}>
                {products.length > 0 ? (
                    products.map(product => (
                        <li key={product.id} className={styles.productItem} onClick={() => selectProduct(product)}>
                            <img src={product.image} className={styles.productImage} alt={product.name} />
                            <div className={styles.ProductCol}>
                                <h3 className={styles.productName}>{product.name}</h3>
                                <p className={styles.productPrice}>{product.price}₺</p>
                            </div>
                        </li>
                    ))
                ) : (
                    <p className={styles.noProducts}>Ürün bulunamadı.</p>
                )}
            </ul>
        </div>
    );
}

export default ProductList;
