import React from "react";
import styles from './Home.module.css';

function CategoryList({ categories, selectCategory }) {
    if (!categories || categories.length === 0) {
        return <p className={styles.noCategories}>Kategoriler Yükleniyor...</p>;
    }

    const sortedCategories = categories.sort((a, b) => a.order - b.order);

    return (
        <div>
            <ul className={styles.categoryList}>
                {sortedCategories
                    .filter(category => category.isInStock)
                    .map(category => (
                        <li key={category.id} className={styles.categoryItem} onClick={() => selectCategory(category)}>
                            <h3 className={styles.categoryName}>{category.name.toUpperCase()}</h3>
                            <img src={category.image} className={styles.categoryImage} alt={category.name} />
                        </li>
                    ))}
            </ul>
        </div>
    );
}


export default CategoryList;
