import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import KategoriYonetimi from "./AdminCategory";
import UrunYonetimi from "./UrunYonetimi";
import AccessReports from "./AccessReports";
import Sidebar from "../Sidebar/Sidebar";
import ProtectedRoute from '../ProtectedRoute';
import './AdminPanel.css';

const AdminPanel = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('categories');
  }, []);

  return (
    <div className="admin-panel">
      <Sidebar />
      <div className="main-content">
        <Routes>
          <Route
            path="categories"
            element={<ProtectedRoute element={<KategoriYonetimi />} />}
          />
          <Route
            path="products"
            element={<ProtectedRoute element={<UrunYonetimi />} />}
          />
          <Route
            path="access-reports"
            element={<ProtectedRoute element={<AccessReports />} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default AdminPanel;
