import React, { useEffect, useState } from "react";
import { db, storage } from "../firebase";
import { FaEdit, FaTrash } from 'react-icons/fa';
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import Switch from 'react-switch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AdminPanel.css';
import { v4 as uuidv4 } from 'uuid';

const UrunYonetimi = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentProductImageName, setCurrentProductImageName] = useState("");
  const [newProduct, setNewProduct] = useState({ name: "", description: "", price: "", category_name: "", allergen_info: "", cooking_time: "", image: "", inStock: true });
  const [productImage, setProductImage] = useState(null);
  const [editingProductId, setEditingProductId] = useState(null);
  const [showProductForm, setShowProductForm] = useState(false);
  const [expandedProductId, setExpandedProductId] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchProducts = async () => {
      const productsCollection = collection(db, "products");
      const productSnapshot = await getDocs(productsCollection);
      const productList = productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productList);
    };

    const fetchCategories = async () => {
      const categoriesCollection = collection(db, "categories");
      const categorySnapshot = await getDocs(categoriesCollection);
      const categoryList = categorySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCategories(categoryList);
    };

    fetchProducts();
    fetchCategories();
  }, []);

  const handleAddProduct = async () => {
    try {
      let imageUrl = "";
      if (productImage) {
        imageUrl = await uploadImage(productImage);
      }
      await addDoc(collection(db, "products"), { ...newProduct, image: imageUrl });
      setNewProduct({ name: "", description: "", price: "", category_name: "", allergen_info: "", cooking_time: "", image: "", inStock: true });
      setProductImage(null);
      toast.success("Ürün başarılı bir şekilde eklendi!", {
        closeButton: false,
      });
    } catch (error) {
      toast.error("Ürün eklenirken hata oluştu.", {
        closeButton: false,
      });
    }
  };


  const uploadImage = async (file) => {
    try {
      const uniqueFileName = `${uuidv4()}_${file.name}`;
      const storageRef = ref(storage, `images/${uniqueFileName}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      return url;
    } catch (error) {
      console.error("Resim yüklenirken hata oluştu:", error);
      throw error;
    }
  };


  const handleDeleteProduct = async (id) => {
    try {
      await deleteDoc(doc(db, "products", id));
      toast.success("Ürün silindi!", {
        closeButton: false,
      });
    } catch (error) {
      toast.error("Ürün silinirken hata oluştu.", {
        closeButton: false,
      });
    }
  };


  const handleEditProduct = (product) => {
    setEditingProductId(product.id);
    setNewProduct(product);
    setExpandedProductId(product.id);
    setCurrentProductImageName(product.imageName);
  };

  const handleUpdateProduct = async () => {
    try {
      const productRef = doc(db, "products", editingProductId);
      let updatedProduct = { ...newProduct };

      if (productImage) {
        const imageUrl = await uploadImage(productImage);
        updatedProduct = { ...updatedProduct, image: imageUrl };
      }

      await updateDoc(productRef, updatedProduct);
      setNewProduct({ name: "", description: "", image: "", allergen_info: "", cooking_time: "", price: "", isInStock: true });
      setProductImage(null);
      setEditingProductId(null);
      setExpandedProductId(null);
      toast.success("Ürün güncellendi!", {
        closeButton: false,
      });
    } catch (error) {
      toast.error("Ürün güncellenirken hata oluştu.", {
        closeButton: false,
      });
    }
  };

  const handleToggleInStock = async (product) => {
    try {
      const productRef = doc(db, "products", product.id);
      await updateDoc(productRef, { inStock: !product.inStock });
      setProducts(products.map(p => p.id === product.id ? { ...p, inStock: !p.inStock } : p));
      toast.success("Ürün stok durumu güncellendi!",
        { closeButton: false, });
    } catch (error) {
      toast.error("Stok durumu güncellenirken hata oluştu.",
        { closeButton: false, });
    }
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(search.toLowerCase()) ||
    product.category_name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div>
      <ToastContainer position="top-center" theme="dark" />
      <h3>Ürünler</h3>
      <div className="button-container">
        <h3 onClick={() => setShowProductForm(!showProductForm)}>Ürün Ekle</h3>
        <input type="text"
          placeholder="Ürün adı veya kategori ara..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {showProductForm && (
        <div className="form-group">
          <input
            type="text"
            placeholder="Ürün Adı"
            value={newProduct.name}
            onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
            className="input-field"
          />
          <input
            type="text"
            placeholder="Açıklama"
            value={newProduct.description}
            onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
            className="input-field"
          />
          <input
            type="text"
            placeholder="Fiyat"
            value={newProduct.price}
            onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
            className="input-field"
          />
          <select
            value={newProduct.category_name}
            onChange={(e) => setNewProduct({ ...newProduct, category_name: e.target.value })}
            className="input-field"
          >
            <option value="">Kategori Seçin</option>
            {categories.map(category => (
              <option key={category.id} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Alerjen Bilgisi"
            value={newProduct.allergen_info}
            onChange={(e) => setNewProduct({ ...newProduct, allergen_info: e.target.value })}
            className="input-field"
          />
          <input
            type="text"
            placeholder="Pişme Süresi"
            value={newProduct.cooking_time}
            onChange={(e) => setNewProduct({ ...newProduct, cooking_time: e.target.value })}
            className="input-field"
          />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setProductImage(e.target.files[0])}
            className="input-field"
          />
          <p className="image-warn">
            *Görsellerin kaliteli görünmesini sağlamak için yüklenen görsellerin 1000px x 1000px formatında olmasına özen göstermemiz faydalı olacaktır.
          </p>
          <button className="btn" onClick={editingProductId ? handleUpdateProduct : handleAddProduct}>
            {editingProductId ? "Güncelle" : "Ekle"}
          </button>
        </div>
      )}

      <table className="admin-table">
        <thead>
          <tr>
            <th>Ad</th>
            <th>Kategori</th>
            <th>Açıklama</th>
            <th>Fiyat</th>
            <th>Resim</th>
            <th>Stok Durumu</th>
            <th>İşlem</th>
          </tr>
        </thead>
        <tbody>
          {filteredProducts.map(product => (
            <React.Fragment key={product.id}>
              <tr>
                <td>{product.name}</td>
                <td>{product.category_name}</td>
                <td>{product.description}</td>
                <td>{product.price} ₺</td>
                <td><img src={product.image} width={100} height={100} alt={product.name} /></td>
                <td className="switch">
                  <Switch
                    checked={product.inStock}
                    onChange={() => handleToggleInStock(product)}
                    offColor="#888"
                    onColor="#0D6EFD"
                  />
                </td>
                <td >
                  <div className="buttons-column">
                    <button className="btn-edit" onClick={() => handleEditProduct(product)}>
                      <FaEdit />
                    </button>
                    <button className="btn-delete" onClick={() => handleDeleteProduct(product.id)}>
                      <FaTrash />
                    </button>
                  </div>
                </td>
              </tr>
              {expandedProductId === product.id && (
                <tr>
                  <td colSpan="7">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Ürün Adı"
                        value={newProduct.name}
                        onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
                        className="input-field"
                      />
                      <input
                        type="text"
                        placeholder="Açıklama"
                        value={newProduct.description}
                        onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
                        className="input-field"
                      />
                      <input
                        type="text"
                        placeholder="Fiyat"
                        value={newProduct.price}
                        onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
                        className="input-field"
                      />
                      <select
                        value={newProduct.category_name}
                        onChange={(e) => setNewProduct({ ...newProduct, category_name: e.target.value })}
                        className="input-field"
                      >
                        <option value="">Kategori Seçin</option>
                        {categories.map(category => (
                          <option key={category.id} value={category.name}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                      <input
                        type="text"
                        placeholder="Alerjen Bilgisi"
                        value={newProduct.allergen_info}
                        onChange={(e) => setNewProduct({ ...newProduct, allergen_info: e.target.value })}
                        className="input-field"
                      />
                      <input
                        type="text"
                        placeholder="Pişme Süresi"
                        value={newProduct.cooking_time}
                        onChange={(e) => setNewProduct({ ...newProduct, cooking_time: e.target.value })}
                        className="input-field"
                      />
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => setProductImage(e.target.files[0])}
                        className="input-field"
                      />
                      <button className="btn" onClick={handleUpdateProduct}>
                        Güncelle
                      </button>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UrunYonetimi;
