import React, { useEffect, useState } from "react";
import { db, storage } from "../firebase";
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc, writeBatch, query, orderBy } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Switch from "react-switch";
import { FaEdit, FaTrash } from 'react-icons/fa';
import './AdminPanel.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const KategoriYonetimi = () => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({ name: "", description: "", image: "", isInStock: true, order: 0 });
  const [productImage, setProductImage] = useState(null);
  const [editingCategoryId, setEditingCategoryId] = useState(null);
  const [showCategoryForm, setShowCategoryForm] = useState(false);
  const [expandedCategoryId, setExpandedCategoryId] = useState(null);

  const fetchCategories = async () => {
    const categoriesCollection = query(collection(db, "categories"), orderBy("order"));
    const categorySnapshot = await getDocs(categoriesCollection);
    const categoryList = categorySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCategories(categoryList);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleAddCategory = async () => {
    try {
      let imageUrl = "";
      if (productImage) {
        imageUrl = await uploadImage(productImage);
      }
      await addDoc(collection(db, "categories"), { ...newCategory, image: imageUrl });
      setNewCategory({ name: "", description: "", image: "", isInStock: true, order: 0 });
      setProductImage(null);
      toast.success("Kategori eklendi!", { closeButton: false });
      fetchCategories();
    } catch (error) {
      toast.error("Kategori eklenirken hata oluştu.", { closeButton: false });
    }
  };

  const handleMoveCategoryUp = async (category) => {
    const currentIndex = category.order;
    if (currentIndex === 0) return;

    const newOrder = currentIndex - 1;
    const batch = writeBatch(db);

    const categoryRef = doc(db, "categories", category.id);
    batch.update(categoryRef, { order: newOrder });

    const swapCategory = categories.find(cat => cat.order === newOrder);
    if (swapCategory) {
      const swapCategoryRef = doc(db, "categories", swapCategory.id);
      batch.update(swapCategoryRef, { order: currentIndex });
    }

    await batch.commit();
    fetchCategories();
  };

  const handleMoveCategoryDown = async (category) => {
    const currentIndex = category.order;
    const newOrder = currentIndex + 1;
    const batch = writeBatch(db);

    const categoryRef = doc(db, "categories", category.id);
    batch.update(categoryRef, { order: newOrder });

    const swapCategory = categories.find(cat => cat.order === newOrder);
    if (swapCategory) {
      const swapCategoryRef = doc(db, "categories", swapCategory.id);
      batch.update(swapCategoryRef, { order: currentIndex });
    }

    await batch.commit();
    fetchCategories();
  };

  const uploadImage = async (file) => {
    const storageRef = ref(storage, `images/${file.name}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
  };

  const handleDeleteCategory = async (id) => {
    try {
      await deleteDoc(doc(db, "categories", id));
      toast.success("Kategori silindi!", {
        closeButton: false,
      });
      fetchCategories();
    } catch (error) {
      console.error("Kategori silinirken hata oluştu:", error);
      toast.error("Kategori silinirken hata oluştu.", {
        closeButton: false,
      });
    }
  };

  const handleEditCategory = (category) => {
    setEditingCategoryId(category.id);
    setNewCategory({
      name: category.name,
      description: category.description,
      image: category.image,
      isInStock: category.isInStock,
      order: category.order
    });
    setExpandedCategoryId(category.id);
  };

  const handleUpdateCategory = async () => {
    try {
      const categoryRef = doc(db, "categories", editingCategoryId);
      let updatedCategory = { ...newCategory };

      if (productImage) {
        const imageUrl = await uploadImage(productImage);
        updatedCategory = { ...updatedCategory, image: imageUrl };
      }

      const oldCategoryName = categories.find(cat => cat.id === editingCategoryId).name;

      await updateDoc(categoryRef, updatedCategory);

      const productsCollection = collection(db, "products");
      const productsSnapshot = await getDocs(productsCollection);

      const batch = writeBatch(db);

      productsSnapshot.forEach((productDoc) => {
        const productData = productDoc.data();
        if (productData.category_name === oldCategoryName) {
          const productRef = doc(db, "products", productDoc.id);
          batch.update(productRef, { category_name: updatedCategory.name });
        }
      });

      await batch.commit();

      setNewCategory({ name: "", description: "", image: "", isInStock: true, order: 0 });
      setProductImage(null);
      setEditingCategoryId(null);
      setExpandedCategoryId(null);
      toast.success("Kategori ve ilgili ürünler güncellendi!", {
        closeButton: false,
      });
      fetchCategories();
    } catch (error) {
      toast.error("Kategori güncellenirken hata oluştu.", {
        closeButton: false,
      });
    }
  };

  const handleStockToggle = async (category) => {
    try {
      const categoryRef = doc(db, "categories", category.id);
      await updateDoc(categoryRef, { isInStock: !category.isInStock });
      setCategories(categories.map(cat => cat.id === category.id ? { ...cat, isInStock: !cat.isInStock } : cat));
      toast.success("Stok durumu güncellendi!", {
        icon: "✔️",
        closeButton: false,
      });
    } catch (error) {
      toast.error("Stok durumu güncellenirken hata oluştu.", {
        closeButton: false,
      });
    }
  };

  return (
    <div>
      <ToastContainer position="top-center" theme="dark" />
      <h3>Kategoriler</h3>
      <div className="button-container">
        <h3 onClick={() => setShowCategoryForm(!showCategoryForm)}>Kategori Ekle</h3>
      </div>

      {showCategoryForm && (
        <div className="form-group">
          <input
            type="text"
            placeholder="Kategori Adı"
            value={newCategory.name}
            onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
            className="input-field"
          />
          <input
            type="text"
            placeholder="Açıklama"
            value={newCategory.description}
            onChange={(e) => setNewCategory({ ...newCategory, description: e.target.value })}
            className="input-field"
          />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setProductImage(e.target.files[0])}
            className="input-field"
          />
          <button className="btn" onClick={editingCategoryId ? handleUpdateCategory : handleAddCategory}>
            {editingCategoryId ? "Güncelle" : "Ekle"}
          </button>
        </div>
      )}

      <table className="admin-table">
        <thead>
          <tr>
            <th>Ad</th>
            <th>Açıklama</th>
            <th>Resim</th>
            <th>Stok Durumu</th>
            <th>İşlem</th>
            <th>Sıra</th>
          </tr>
        </thead>
        <tbody>
          {categories.map(category => (
            <React.Fragment key={category.id}>
              <tr>
                <td>{category.name}</td>
                <td>{category.description}</td>
                <td><img src={category.image} alt={category.name} /></td>
                <td>
                  <Switch
                    onChange={() => handleStockToggle(category)}
                    checked={category.isInStock}
                    offColor="#888"
                    onColor="#0D6EFD"
                  />
                </td>
                <td>
                  <div className="buttons-column">
                    <button className="btn-edit" onClick={() => handleEditCategory(category)}>
                      <FaEdit />
                    </button>
                    <button className="btn-delete" onClick={() => handleDeleteCategory(category.id)}>
                      <FaTrash />
                    </button>
                  </div>
                </td>
                <td className="order-td">
                  <div className="order-buttons">
                    <button className="order-btn" onClick={() => handleMoveCategoryUp(category)}> <ArrowUpwardIcon /></button>
                    <button className="order-btn" onClick={() => handleMoveCategoryDown(category)}> <ArrowDownwardIcon /></button>
                  </div>
                </td>
              </tr>
              {expandedCategoryId === category.id && (
                <tr>
                  <td colSpan="5">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Kategori Adı"
                        value={newCategory.name}
                        onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
                        className="input-field"
                      />
                      <input
                        type="text"
                        placeholder="Açıklama"
                        value={newCategory.description}
                        onChange={(e) => setNewCategory({ ...newCategory, description: e.target.value })}
                        className="input-field"
                      />
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => setProductImage(e.target.files[0])}
                        className="input-field"
                      />
                      <button className="btn" onClick={editingCategoryId ? handleUpdateCategory : handleAddCategory}>
                        {editingCategoryId ? "Güncelle" : "Ekle"}
                      </button>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default KategoriYonetimi;
