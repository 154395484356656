import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, query, orderBy, onSnapshot, Timestamp } from 'firebase/firestore';
import './AccessReports.css';

const AccessReports = () => {
  const [logs, setLogs] = useState([]);
  const [filter, setFilter] = useState('24h');
  const [deviceCounts, setDeviceCounts] = useState({ Android: 0, iOS: 0, Windows: 0, MacOS: 0, iPad: 0 });
  const [deviceFilter, setDeviceFilter] = useState('all');

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, 'deviceAccessLogs'), orderBy('accessedAt', 'desc'));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const logsArray = [];
        querySnapshot.forEach((doc) => {
          logsArray.push({ id: doc.id, ...doc.data() });
        });
        setLogs(logsArray);
        countDevices(logsArray);
      });

      return () => unsubscribe();
    };

    fetchData();
  }, []);

  const countDevices = (logsArray) => {
    const counts = { Android: 0, iOS: 0, Windows: 0, MacOS: 0, iPad: 0 };

    logsArray.forEach(log => {
      if (log.deviceType in counts) {
        counts[log.deviceType]++;
      }
    });

    setDeviceCounts(counts);
  };

  const filterLogs = () => {
    const now = Timestamp.now();
    let startDate;

    if (filter === '24h') {
      startDate = now.toDate();
      startDate.setHours(startDate.getHours() - 24);
    } else if (filter === '1w') {
      startDate = now.toDate();
      startDate.setDate(startDate.getDate() - 7);
    } else if (filter === '1m') {
      startDate = now.toDate();
      startDate.setMonth(startDate.getMonth() - 1);
    } else if (filter === '6m') {
      startDate = now.toDate();
      startDate.setMonth(startDate.getMonth() - 6);
    } else if (filter === '12m') {
      startDate = now.toDate();
      startDate.setFullYear(startDate.getFullYear() - 1);
    }

    const filteredLogs = logs.filter(log => {
      const accessedAt = log.accessedAt.toDate();
      if (filter !== 'all') {
        return accessedAt >= startDate;
      }
      return true;
    });

    if (deviceFilter !== 'all') {
      return filteredLogs.filter(log => log.deviceType === deviceFilter);
    }

    return filteredLogs;
  };

  const filteredLogs = filterLogs();

  return (
    <div className="access-reports-container">
      <h2>Erişim Raporu</h2>
      <p>*Bu rapor kesin bir sonuç sunmamaktadır yalnızca yaklaşık veriler olduğunu unutmayınız.</p>

      <div className="filter-options">
        <label>
          Tarih Filtre:
          <select value={filter} onChange={(e) => setFilter(e.target.value)}>
            <option value="24h">Son 24 Saat</option>
            <option value="1w">Son 1 Hafta</option>
            <option value="1m">Son 1 Ay</option>
            <option value="6m">Son 6 Ay</option>
            <option value="12m">Son 12 Ay</option>
            <option value="all">Tümü</option>
          </select>
        </label>
      </div>

      <h3>Cihaz Türleri:</h3>
      <div className="device-filters">
        <ul>
          <li onClick={() => setDeviceFilter('all')} className={deviceFilter === 'all' ? 'selected' : ''}>
            Tümü
          </li>
          {Object.keys(deviceCounts).map((device) => (
            <li
              key={device}
              onClick={() => setDeviceFilter(device)}
              className={deviceFilter === device ? 'selected' : ''}
            >
              {device}: {deviceCounts[device]}
            </li>
          ))}
        </ul>
      </div>

      <table>
        <thead>
          <tr>
            <th>Cihaz Bilgisi</th>
            <th>Erişim Tarihi</th>
          </tr>
        </thead>
        <tbody>
          {filteredLogs.map((log) => (
            <tr key={log.id}>
              <td>{log.deviceType}</td>
              <td>{log.accessedAt.toDate().toLocaleString('tr-TR', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AccessReports;
