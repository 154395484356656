import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBiwvoQzvKFo9rQ51-8ej65dxxpk7lEnnc",
  authDomain: "qr-menu-f1dd5.firebaseapp.com",
  projectId: "qr-menu-f1dd5",
  storageBucket: "qr-menu-f1dd5.appspot.com",
  messagingSenderId: "839298062838",
  appId: "1:839298062838:web:0136575741d5895cad8fd7",
  measurementId: "G-H1DVMNGGQM"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
 
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export default app;
