// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home/Home';
import Login from './Login';
import AdminPanel from './Admin/AdminPanel';
import ProductModal from './Home/ProductModal';
import Footer from './Footer/Footer';

const App = () => {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/home/:categoryName" element={<Home />} />
          <Route path="/home/:categoryName/:productName" element={<ProductModal />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin/*" element={<AdminPanel />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
