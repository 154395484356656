import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../firebase";
import { collection, getDocs, addDoc, serverTimestamp } from "firebase/firestore";
import CategoryList from "./CategoryList";
import ProductList from "./ProductList";
import ProductModal from './ProductModal';
import bannerImage from '../Assets/banner.jpg';
import styles from './Home.module.css';
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const Home = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate();
  const [showScroll, setShowScroll] = useState(false);
  const { categoryName } = useParams();


  const getDeviceType = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/android/.test(userAgent)) return "Android";
    if (/iphone|ipad|ipod/.test(userAgent)) return "iOS";
    if (/windows/.test(userAgent)) return "Windows";
    if (/macintosh/.test(userAgent)) return "MacOS";
    if (/ipad/.test(userAgent)) return "iPad";
    return "Unknown";
  };

  const logDeviceAccess = async () => {
    try {
      const deviceType = getDeviceType();
      await addDoc(collection(db, 'deviceAccessLogs'), {
        deviceType,
        accessedAt: serverTimestamp(),
      });
    } catch (e) {
      console.error("Error logging device access: ", e);
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      const categoryCollection = collection(db, "categories");
      const categorySnapshot = await getDocs(categoryCollection);
      const categoryList = categorySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCategories(categoryList);
    };

    fetchCategories();
    logDeviceAccess();
  }, []);

  useEffect(() => {
    if (categoryName) {
      const category = categories.find(cat => cat.name === categoryName);
      if (category) {
        setSelectedCategory(category);
      }
    } else {
      setSelectedCategory(null);
      setSelectedProduct(null);
    }
  }, [categoryName, categories]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    navigate(`/home/${category.name}`);
  };

  const handleProductSelect = (product) => {
    if (product) {
      setSelectedProduct(product);
      setModalIsOpen(true);
      // Yönlendirme eklenebilir, ancak pop-up'ı açmamız gerektiği için burayı kullanmıyoruz.
      // navigate(`/home/${selectedCategory.name}/${product.name}`);
    } else {
      console.error("Ürün bilgileri bulunamadı.");
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedProduct(null);
  };

  const handleBack = () => {
    if (modalIsOpen) {
      closeModal();
      return;
    }

    if (selectedProduct) {
      navigate(`/home/${selectedCategory.name}/${selectedProduct.name}`);
      return;
    } else if (selectedCategory) {
      setSelectedCategory(null);
      navigate('/home');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        <h2 className={styles.bannerText}>Coffee Paradise & Bistro</h2>
        <img src={bannerImage} alt="Banner" className={styles.bannerImage} />
      </div>

      <h1 className={styles.title}>MENÜ</h1>

      {selectedCategory ? (
        <ProductList
          category={selectedCategory}
          selectProduct={handleProductSelect}
          goBack={handleBack}
        />
      ) : (
        <CategoryList categories={categories} selectCategory={handleCategorySelect} />
      )}

      <ProductModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        product={selectedProduct}
      />

      {showScroll && (
        <IconButton
          onClick={scrollToTop}
          aria-label="scroll to top"
          className={styles.scrollTopButton}
          sx={{
            backgroundColor: '#9fcb52',
            color: 'white',
            width: 50,
            height: 50,
            borderRadius: '50%',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            transition: 'opacity 0.4s ease-out',
            position: 'fixed',
            bottom: 20,
            right: 20,
            zIndex: 1000,
            '&:hover': {
              backgroundColor: '#86b04e',
            }
          }}
        >
          <ArrowUpwardIcon />
        </IconButton>

      )}

    </div>
  );
};

export default Home;
