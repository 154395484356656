import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import CategoryIcon from '@mui/icons-material/Category';
import StoreIcon from '@mui/icons-material/Store';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LogoutIcon from '@mui/icons-material/Logout';
import { auth } from '../firebase';
import './Sidebar.css';

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/home');
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <button onClick={toggleSidebar} className="toggle-btn">
        {isCollapsed ? <MenuIcon /> : <CloseIcon />}
      </button>
      <nav>
        <ul>
          <li>
            <NavLink to="categories" className={({ isActive }) => (isActive ? 'active' : '')}>
              {isCollapsed ? <CategoryIcon /> : 'Kategori Yönetimi'}
            </NavLink>
          </li>
          <li>
            <NavLink to="products" className={({ isActive }) => (isActive ? 'active' : '')}>
              {isCollapsed ? <StoreIcon /> : 'Ürün Yönetimi'}
            </NavLink>
          </li>
          <li>
            <NavLink to="access-reports" className={({ isActive }) => (isActive ? 'active' : '')}>
              {isCollapsed ? <AssessmentIcon /> : 'Erişim Raporları'}
            </NavLink>
          </li>
            <button onClick={handleLogout} className="logout-btn">
              {isCollapsed ? <LogoutIcon /> : 'Çıkış Yap'}
            </button>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
