import React from "react";
import Modal from "react-modal";
import styles from './ProductModal.module.css';

Modal.setAppElement('#root');

const ProductModal = ({ isOpen, onRequestClose, product }) => {
    if (!product) return null;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Ürün Detayları"
            className={styles.modal}
            overlayClassName={styles.overlay}
        >
            <button className={styles.closeButton} onClick={onRequestClose}>
                &times;
            </button>
            <img src={product.image} width={200} height={200} alt={product.name} />
            <h2>{product.name}</h2>
            <p>{product.description}</p>
            <p><strong>Alerjenik Bilgi:</strong> {product.allergen_info}</p>
            <p><strong>Pişme Süresi:</strong> {product.cooking_time} dakika</p>
            <p><strong>Fiyat:</strong> {product.price} TL</p>
            <button onClick={onRequestClose}>Kapat</button>
        </Modal>
    );
};

export default ProductModal;
